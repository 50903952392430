

export const timelineData = [
    {
        year: "1955",
        image: require("../../assets/uploads/1955_5bc4773d3e291.jpg"),
        translationKey: "corporate-history-1955"
    },
    {
        year: "1967",
        image: require("../../assets/uploads/1967_5bc477364cd69.jpg"),
        translationKey: "corporate-history-1967"
    },
    {
        year: "1968",
        image: require("../../assets/uploads/1968_5bc4771eb8d79.jpg"),
        translationKey: "corporate-history-1968"
    },
    {
        year: "1975",
        image: require("../../assets/uploads/1975_5e40e8c4dd948.jpg"),
        translationKey: "corporate-history-1975"
    },
    {
        year: "1980",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-1980"
    },
    {
        year: "1983",
        image: require("../../assets/uploads/1983_5bc4770f00016.jpg"),
        translationKey: "corporate-history-1983"
    },
    {
        year: "1984",
        image: require("../../assets/uploads/1984_5bc47701ed7b8.jpg"),
        translationKey: "corporate-history-1984"
    },
    {
        year: "1985",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-1985"
    },
    {
        year: "1986",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-1986"
    },
    {
        year: "1990",
        image: require("../../assets/uploads/1990_5bc476f352a00.jpg"),
        translationKey: "corporate-history-1990"
    },
    {
        year: "1995",
        image: require("../../assets/uploads/1995_5bc5ca87eef5b.jpg"),
        translationKey: "corporate-history-1995"
    },
    {
        year: "1996",
        image: require("../../assets/uploads/1996_5bc476db530e7.jpg"),
        translationKey: "corporate-history-1996"
    },
    {
        year: "1997",
        image: require("../../assets/uploads/1997_5bc476d1f2938.jpg"),
        translationKey: "corporate-history-1997"
    },
    {
        year: "1997",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-1997-2"
    },
    {
        year: "1997",
        image: require("../../assets/uploads/1997_5bc4769d40aa7.jpg"),
        translationKey: "corporate-history-1997-3"
    },
    {
        year: "1997",
        image: require("../../assets/uploads/1997_5bc47692c11e7.jpg"),
        translationKey: "corporate-history-award"
    },
    {
        year: "1998",
        image: require("../../assets/uploads/1997_5bc47692c11e7.jpg"),
        translationKey: "corporate-history-award"
    },
    {
        year: "1999",
        image: require("../../assets/uploads/1997_5bc47692c11e7.jpg"),
        translationKey: "corporate-history-award"
    },
    {
        year: "2000",
        image: require("../../assets/uploads/1997_5bc47692c11e7.jpg"),
        translationKey: "corporate-history-award"
    },
    {
        year: "2000",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2000"
    },
    {
        year: "2000",
        image: require("../../assets/uploads/2000_5bc5cb00cd5a1.jpg"),
        translationKey: "corporate-history-2000-2"
    },
    {
        year: "2002",
        image: require("../../assets/uploads/2002_5bc4763ba927c.jpg"),
        translationKey: "corporate-history-2002"
    },
    {
        year: "2003",
        image: require("../../assets/uploads/2003_5bc476300d767.jpg"),
        translationKey: "corporate-history-2003"
    },
    {
        year: "2003",
        image: require("../../assets/uploads/2003-cigli-1.jpg"),
        translationKey: "corporate-history-2003-2"
    },
    {
        year: "2003",
        image: require("../../assets/uploads/2003_5bc47602729e7.jpg"),
        translationKey: "corporate-history-2003-3"
    },
    {
        year: "2004",
        image: require("../../assets/uploads/2004_5bc475f13b862.jpg"),
        translationKey: "corporate-history-2004"
    },
    {
        year: "2004",
        image: require("../../assets/uploads/2004_5bc475c78483e.jpg"),
        translationKey: "corporate-history-2004-2"
    },
    {
        year: "2004",
        image: require("../../assets/uploads/2004_5bc5cb40246c4.jpg"),
        translationKey: "corporate-history-2004-3"
    },
    {
        year: "2004",
        image: require("../../assets/uploads/2003_5bc47602729e7.jpg"),
        translationKey: "corporate-history-2004-4"
    },
    {
        year: "2005",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2005"
    },
    {
        year: "2006",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2006"
    },
    {
        year: "2008",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2008"
    },
    {
        year: "2009",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2009"
    },
    {
        year: "2009",
        image: require("../../assets/uploads/2009_5bc4759d1ae4b.jpg"),
        translationKey: "corporate-history-2009-2"
    },
    {
        year: "2013",
        image: require("../../assets/uploads/2013_5bc475906b2f2.jpg"),
        translationKey: "corporate-history-2013"
    },
    {
        year: "2013",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2013-2"
    },
    {
        year: "2014",
        image: require("../../assets/uploads/2014_5bc34fb62c685.jpg"),
        translationKey: "corporate-history-2014"
    },
    {
        year: "2016",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2016"
    },
    {
        year: "2018",
        image: require("../../assets/uploads/2018_5bc5cc6fedc52.jpg"),
        translationKey: "corporate-history-2018"
    },
    {
        year: "2018",
        image: require("../../assets/uploads/2018_5bc34fae619b7.jpg"),
        translationKey: "corporate-history-2018-2"
    },
    {
        year: "2018",
        image: require("../../assets/uploads/2018_5bd310996e5b8.jpg"),
        translationKey: "corporate-history-2018-3"
    },
    {
        year: "2021",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2021"
    },
    {
        year: "2021",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2021-2"
    },
    {
        year: "2021",
        image: require("../../assets/uploads/2021_secondfactory.webp"),
        translationKey: "corporate-history-2021-3"
    },
    {
        year: "2021",
        image: require("../../assets/uploads/cds_logo.webp"),
        translationKey: "cds-build"
    },
    {
        year: "2022",
        image: require("../../assets/uploads/2022_production.webp"),
        translationKey: "corporate-history-2022"
    },
    {
        year: "2022",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "corporate-history-2022-2"
    },
    {
        year: "2022",
        image: require("../../assets/uploads/Ores_Alloy_Wheels1.webp"),
        translationKey: "ores-build"
    },
    {
        year: "2023",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "next10"
    },
    {
        year: "2024",
        image: require("../../assets/uploads/timeline-placeholder-1.webp"),
        translationKey: "farklabas-coop"
    },
];
