import { useTranslation } from "react-i18next";
import { timelineData } from "./TimeObjects";

const Timeline = (props) => {
  const { t } = useTranslation();

  return (
    <div className={props.scrollable ? "c-corporate-timeline-scroll" : ""}>
      <div className="c-timeline-lines" />
      <div className="c-timeline">
        {timelineData.map((item, index) => (
          <div className="c-timeline-item" key={`timeline-${index}`}>
            <div className="c-timeline-image c-ratio">
              <img
                className="c-ratio-element"
                alt={item.year}
                src={item.image}
              />
            </div>
            <div className="c-timeline-content">
              <div className="c-timeline-date">{item.year}</div>
              <div>{t(item.translationKey)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
